angular.module('webpanel').directive('runeVideo', function($rootScope, $document, $timeout, api) {
	var videoElement;

	var hlsInstance;
	var dashInstance;

	var licenseUrl;

	var noAutoplayPlayTimeout;
	var childScope;

	// -----------------------------------------------------

	// nie trzeba niszczyć tych listenerów bo element jest inicjalizowany raz przy załadowaniu strony
	$rootScope.$on('runeVideoGoFullscreen', function() {
		var parent = videoElement.parentNode;

		if (parent.requestFullscreen) {
			parent.requestFullscreen();
		} else if (parent.mozRequestFullScreen) {
			parent.mozRequestFullScreen();
		} else if (parent.webkitRequestFullscreen) {
			parent.webkitRequestFullscreen();
		} else {
			$rootScope.$broadcast('runeVideoFullscreenFail');
		}
	});

	$rootScope.$on('runeVideoExitFullscreen', function() {
		// jeśli się dało wejść, to bedzie się też dało wyjśc...?
		// $document nie działa ponieważ bo tak(?)

		if (document.cancelFullScreen) {
			document.cancelFullScreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitCancelFullScreen) {
			document.webkitCancelFullScreen();
		} else if(document.exitFullscreen) {
			document.exitFullscreen();
		}
	});

	$rootScope.$on('runeVideoVolumeChange', function(_, newVolume) {
		videoElement.volume = newVolume;
	});

	$rootScope.$on('runeVideoMuteToggle', function() {
		videoElement.muted = !videoElement.muted;
	});

	$rootScope.$on('runeVideoMuteSet', function(_, state) {
		videoElement.muted = state;
	});

	$rootScope.$on('runeLicenseUriSet', function(_, protectionUrl) {
		licenseUrl = protectionUrl;
		if(typeof dashInstance === 'undefined' || !dashInstance) return;

		dashInstance.setProtectionData({
			'com.widevine.alpha': {
				serverURL: api.getRoot()+licenseUrl,
				httpRequestHeaders: {
					foo: function() {
						return api.authCallback(licenseUrl).headers;
					},
				},
				videoRobustness: 'SW_SECURE_CRYPTO',
				audioRobustness: 'SW_SECURE_CRYPTO'
			}
		});
	});

	$rootScope.$on('runeVideoQualityChange', function(_, requestedQuality) {
		requestedQuality = parseInt(requestedQuality);

		try {
			var hlsInstance = getHlsInstance();

			if(typeof hlsInstance !== 'undefined') {
				hlsInstance.currentLevel = requestedQuality;
			}
		} catch(e) { }

		try {
			if(typeof dashInstance !== 'undefined') {
				var availableBitrates = dashInstance.getBitrateInfoListFor('video');

				for(var i in availableBitrates) {
					if(availableBitrates[i].qualityIndex === requestedQuality) {
						// TODO znajdź poprzedni żeby ustawić minimum?
						dashInstance.updateSettings({
							streaming: {
								abr: {
									minBitrate: { 'video': availableBitrates[i].bitrate/1000 },
									maxBitrate: { 'video': availableBitrates[i].bitrate/1000 },
									limitBitrateByPortal: false,
								}
							}
						});

						break;
					}
				}
			}
		} catch(e) { }
	});

	// -----------------------------------------------------

	$document.on('webkitfullscreenchange', function() {
		$rootScope.$broadcast('runeVideoFullscreenChange');
	});

	$document.on('mozfullscreenchange', function() {
		$rootScope.$broadcast('runeVideoFullscreenChange');
	});

	$document.on('fullscreenchange', function() {
		$rootScope.$broadcast('runeVideoFullscreenChange');
	});

	// -----------------------------------------------------

	var initHLSInstance = function() {
		try {
			hlsInstance.destroy();
		} catch(e) {}

		hlsInstance = new Hls({
			//debug: true,
		});

		hlsInstance.on(Hls.Events.MANIFEST_PARSED, function() {
			videoElement.play();
			$rootScope.$broadcast('runeVideoLevelsAvailable', hlsInstance.levels);
		});

		hlsInstance.on(Hls.Events.LEVEL_SWITCHED, function() {
			$rootScope.$broadcast('runeVideoLevelChange', hlsInstance.currentLevel);
		});

		hlsInstance.on(Hls.Events.FRAG_LOADED , function() {
			$rootScope.$broadcast('runeVideoFragmentLoaded');
		});

		hlsInstance.on(Hls.Events.LEVEL_UPDATED, function() {
			$rootScope.$broadcast('runeVideoLevelsAvailable', hlsInstance.levels);
			$rootScope.$broadcast('runeVideoLevelChange', hlsInstance.currentLevel);
		});

		hlsInstance.on(Hls.Events.ERROR, function(_, error) {
			if(error.fatal) {
				if(Hls.isSupported()) {
					childScope.runeSource.bsReference = null;

					hlsInstance.stopLoad();
					hlsInstance.detachMedia();

					hlsInstance.destroy();
					initHLSInstance();
				} else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
					videoElement.src = '';
				}

				var internalErrorType;
				switch(error.type) {
					case Hls.ErrorTypes.NETWORK_ERROR:
						internalErrorType = 'network';
					break;
					case Hls.ErrorTypes.MEDIA_ERROR:
						internalErrorType = 'media';
					break;
					default:
						internalErrorType = 'other';
				}

				$rootScope.$broadcast(
					'runeVideoError',
					internalErrorType,
					typeof(error.details === 'string') ? error.details : null
				);
			}
		});
	}

	var initDashInstance = function(url) {
		dashInstance = dashjs.MediaPlayer().create();
		dashInstance.initialize(videoElement, url, true);

		dashInstance.setProtectionData({
			'com.widevine.alpha': {
				serverURL: api.getRoot()+licenseUrl,
				httpRequestHeaders: {
					foo: function() {
						return api.authCallback(licenseUrl).headers;
					},
				},
				videoRobustness: 'SW_SECURE_CRYPTO',
				audioRobustness: 'SW_SECURE_CRYPTO'
			}
		});

		dashInstance.on(dashjs.MediaPlayer.events.MANIFEST_LOADED, function() {
			dashInstance.updateSettings({
				debug: {
					logLevel: dashjs.Debug.LOG_LEVEL_NONE
				},
				streaming: {
					stableBufferTime: 5,
    				bufferTimeAtTopQuality: 10,
					abr: {
						limitBitrateByPortal: false,
					}
				}
			});

			videoElement.play();
		});

		dashInstance.on(dashjs.MediaPlayer.events.STREAM_INITIALIZED, updateDashQualities);
		dashInstance.on(dashjs.MediaPlayer.events.PLAYBACK_PLAYING, setAudioTrack);
		dashInstance.on(dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED, updateDashCurrentQuality);

		dashInstance.on(dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED, function() {
			$rootScope.$broadcast('runeVideoFragmentLoaded');
		});

		dashInstance.on(dashjs.MediaPlayer.events.QUALITY_CHANGE_REQUESTED, function() {
			updateDashQualities();
			$rootScope.$broadcast('runeVideoLevelChange', -1);
		});

		var onError = function(e) {
			$rootScope.$broadcast(
				'runeVideoError',
				e.error.code < 100 ? 'media' : 'drm',
				e.error.code
			);
		}

		dashInstance.on(dashjs.MediaPlayer.events.PLAYBACK_ERROR, onError);
		dashInstance.on(dashjs.MediaPlayer.events.ERROR, onError);

		dashInstance.attachSource(url);
	}

	var setAudioTrack = function() {
		var currentAudioTrack = dashInstance.getCurrentTrackFor('audio');
		var audioTracks = dashInstance.getTracksFor('audio');
		console.log(audioTracks);
		if (currentAudioTrack.lang !== 'pol' && audioTracks.length > 1) {
			for (var i in audioTracks) {
				if (audioTracks[i].lang === 'pol') {
						dashInstance.setCurrentTrack(audioTracks[i]);
					break;
				}
			}
		}
		$rootScope.$broadcast('runeVideoAudioTracksAvailable', audioTracks);
	}

	var updateDashQualities = function() {
		$rootScope.$broadcast('runeVideoLevelsAvailableDash', dashInstance.getBitrateInfoListFor('video'));
	}

	var updateDashCurrentQuality = function() {
		var streamInfo = dashInstance.getActiveStream().getStreamInfo();
		var dashMetrics = dashInstance.getDashMetrics();
		var dashAdapter = dashInstance.getDashAdapter();

		if (dashMetrics && streamInfo) {
			var repSwitch = dashMetrics.getCurrentRepresentationSwitch('video', true);
			var currentBitrate = repSwitch ? dashAdapter.getBandwidthForRepresentation(repSwitch.to, streamInfo.index) : 0;
		}

		var availableBitrates = dashInstance.getBitrateInfoListFor('video');

		for(var i in availableBitrates) {
			if(availableBitrates[i].bitrate === currentBitrate) {
				$rootScope.$broadcast('runeVideoLevelChange', availableBitrates[i].qualityIndex);
				break;
			}
		}
	}

	var destroyInstances = function() {
		if(typeof hlsInstance !== 'undefined') {
			try {
				hlsInstance.stopLoad();
				hlsInstance.detachMedia();
			} catch(e) {
				// to chyba można zjeść?
			}
		}

		if(typeof dashInstance !== 'undefined') {
			try {
				dashInstance.reset();
			} catch(e) {
				// to też raczej można zjeść...?
			}
		}
	}

	var getHlsInstance = function() {
		return hlsInstance;
	}

	return {
		scope: {
			runeSource: '=',
		},

		link: function(scope, element) {
			videoElement = element[0];
			childScope = scope;

			// -----------------------------------------------------

			videoElement.addEventListener('canplay', function() {
				videoElement.play();
			});

			videoElement.addEventListener('play', function() {
				$rootScope.$broadcast('runeVideoPlay');
			});

			videoElement.addEventListener('pause', function() {
				$rootScope.$broadcast('runeVideoPause');
			});

			// -----------------------------------------------------

			scope.$watch('runeSource', function(newValue, oldValue) {
				if(JSON.stringify(newValue.bsReference) == JSON.stringify(oldValue.bsReference)) return;

				// najpierw zatrzymaj obecne żeby nie było poprzedniego chunka
				// i discontinuity po rozpoczęciu odtwarzania nowego źródła
				videoElement.pause();
				$timeout.cancel(noAutoplayPlayTimeout);

				destroyInstances();

				if(typeof newValue.bsReference === 'undefined' || !newValue.bsReference) return;

				if(newValue.bsReference.match(/\.m3u8/)) {
					if(newValue.bsReference !== null) {
						if(Hls.isSupported()) {
							initHLSInstance();
							hlsInstance.loadSource(newValue.bsReference);
							hlsInstance.attachMedia(videoElement);
						} else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
							noAutoplayPlayTimeout = $timeout(function() {
								// jeśli wywołam to zaraz po pause() to wideo się nie zapauzuje na ios...
								videoElement.src = newValue.bsReference;
							}, 1000);
						}
					} else {
						hlsInstance.stopLoad();
						hlsInstance.detachMedia();
					}

				} else if(newValue.bsReference.match(/\.(ism|livx|mpd)/)) {
					initDashInstance(newValue.bsReference);
				} else {
					throw new TypeError('unsupported playlist type');
				}


			}, true);

			scope.$on('$destroy', function() {
				hlsInstance.destroy();
				videoElement = null;
				hlsInstance = null;
			});
		}
	}
});