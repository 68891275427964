angular.module('webpanel').service('setting', ['$timeout', '$rootScope', 'device', 'api', 'user', 'socket', 'popup',
	function($timeout, $rootScope, device, api, user, socket, popup) {

	var settings = {};
	var observers = {};

	var delayedWriteTimeout;

	var fetchAll = function() {
		api.settingsFetch().then(function(response) {
			settings = response.data;
			notifyObservers('change');
		}).catch(function() {
			if(user.isDemo()) return;

			var pop = popup.create();
			pop.setText('Wystąpił błąd podczas pobierania danych. Spróbuj ponownie. '+
				'Jeśli problem będzie się powtarzał, skontaktuj się ze swoim Operatorem.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);
		});
 	}

	var write = function() {
		api.settingsWrite(settings).then(function() {
			notifyObservers('writeSuccess');
		}).catch(function() {
			notifyObservers('writeFail');

			var pop = popup.create();
			pop.setText('Nie udało się zapisać zmian, Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);
		});
	}

	var set = function(name, value) {
		if(!user.has()) throw new Error('user not present');
		$timeout.cancel(delayedWriteTimeout);

		if(value) {
			settings[name] = value;
		} else {
			delete settings[name];
		}

		delayedWriteTimeout = $timeout(function() {
			write();
			notifyObservers('change');
		}, 1000);
	}

	var get = function() {
		return settings;
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) observers[i](eventType, eventData);
	}

	var update = function() {
		if(device.has()) {
			fetchAll();
		}
	}

	var getAutozap = function() {
		var autozapList = [];
		var settingsLocal = get();

		for(var name in settingsLocal) {
			if(name.match(/^autozap_\d+$/) && settingsLocal[name] && settingsLocal[name] !== 'null') {
				autozapList.push(settings[name]);
			}
		}

		return autozapList;
	}

	var setAutozap = function(autozapList) {
		var settingsLocal = get();

		for(var name in settingsLocal) {
			if(name.match(/^autozap_\d+$/)) {
				set(name, null);
				delete settingsLocal[name];
			}
		}

		var keys = Object.keys(autozapList);

		for(var i=0; i<keys.length; i++) {
			set('autozap_'+i, autozapList[keys[i]]);
		}
	}

	var isLocked = function(channelId) {

		for(var name in settings) {
			if(name === 'parentallock_'+channelId.toString()) return true;
		}
		return false;
	}

	$rootScope.$on('deviceAcquired', fetchAll);

	$rootScope.$on('deviceLost', function() {
		settings = {};
		notifyObservers('change');
	});

	socket.addObserver('settingSocketObserver', 'setting', update);
	update();

	return {
		get: get,
		set: set,
		getAutozap: getAutozap,
		setAutozap: setAutozap,
		addObserver: addObserver,
		removeObserver: removeObserver,
		isLocked: isLocked,
	}

}]);