angular.module('webpanel').service('cast', ['ott', 'api', '$interval', 'chromecast', function(ott, api, $interval, chromecast) {
	var observers = {};

	var castAdapter = null;
	var castAvailable = false;

	var castSessionData = {};
	var castOttToken = null;

	var channelId;

	var destroy = function() {
		castAdapter.disconnect();
		castAdapter = null;
		castAvailable = false;

		ott.destroy();
		notifyObservers('destroy');
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) observers[i](eventType, eventData);
	}

	var getSessionData = function() {
		if(typeof castSessionData.id !== 'undefined') return Promise.resolve(castSessionData);

		return new Promise(function(resolve, reject) {
			api.castGetSession().then(function(response) {
				castSessionData = response.data;
				resolve(response.data);
			}).catch(function(e) {
				reject(e);
			});
		});
	}

	var getOttToken = function(forDeviceId) {
		if(castOttToken) return Promise.resolve(castOttToken);

		return new Promise(function(resolve, reject) {
			api.castGetToken(forDeviceId).then(function(response) {
				castOttToken = response.data.token;
				resolve(response.data.token);
			}).catch(function(e) {
				reject(e);
			});
		});
	}

	var authBorrowInterval;

	var startBorrowInterval = function() {
		$interval.cancel(authBorrowInterval);

		sendAuthData();
		authBorrowInterval = $interval(sendAuthData, 60*1000);
	}

	var sendAuthData = function() {
		var apiUrl = "https://api-dev.sgtsa.pl/public/" || 'https://api.sgtsa.pl/';
		try {
			getSessionData().then(function() {
				sendMessage({
					apiUrl: apiUrl,
					seed: castSessionData.seed,
					id: castSessionData.id,
					channel: channelId,
				});
			}).catch(function(e) {
				notifyObservers('error', e);
			});
		} catch(e) {
			notifyObservers('error', e);
		}
	}

	var stopBorrowInterval = function() {
		$interval.cancel(authBorrowInterval);
	}

	var start = function(url, title, description, channel) {
		channelId = channel;

		var media = {
			content: url,
			title: title,
			description: description,
		}

		try {
			getSessionData().then(function(session) {
				media.content = media.content.replace(/hash=[^&]*/i, 'hash='+encodeURIComponent(session.id));
				return getOttToken(session.id);
			}).then(function(token) {
				media.content = media.content.replace(/token=[^&]*/i, 'token='+encodeURIComponent(token));
				return castAdapter.cast(media);
			}).then(function() {
				notifyObservers('start');
			}).catch(function(e) {
				notifyObservers('error', e);
			});
		} catch(e) {
			notifyObservers('error', e);
		}
	}

	var startDash = function(uuid, title, description, channel) {
		channelId = channel;

		var castId = null;
		var castSeed = null;

		api.castGetSession().then(function(session) {
			castId = session.data.id;
			castSeed = session.data.seed;
			
			return Promise.all([
				api.castGetToken(castId),
				api.ottDash(uuid),
			]);
		}).then(function(response) {
			var playlistData = response[1].data;

			if(!playlistData.url.match(/^https?/)) {
				playlistData.url = 'https:'+playlistData.url;
			}

			castAdapter.Connected = true;
			castAdapter.Player.isConnected = true;
			
			cast.framework.CastContext.getInstance().requestSession().then(function() {
				var session = cast.framework.CastContext.getInstance().getCurrentSession();		 
		
				var mediaInfo = new chrome.cast.media.MediaInfo(playlistData.url);
				mediaInfo.contentType = 'application/dash+xml';
				
				mediaInfo.customData = {
					id: castId,
					seed: castSeed,
					license: playlistData.license,
				};
				
				mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
				mediaInfo.metadata.title = title;
				mediaInfo.metadata.subtitle = description;
				
				var request = new chrome.cast.media.LoadRequest(mediaInfo);
				request.currentTime = 0;
				request.autoplay = true;
		
				session.loadMedia(request).then(function() {
					notifyObservers('start');
				}).catch(function(e) {
					notifyObservers('error', e);
				});
			});
		});
	}

	var receiverEventHandler = function(_, event) {
		try {
			var eventParsed = JSON.parse(event);
			notifyObservers(eventParsed.type, eventParsed.data);
		} catch(e) {
			notifyObservers('error', e);
		}
	}

	var prepare = function() {
		var receiverID = "229ADF6E" || '229ADF6E';
		castAdapter = chromecast.make('origin_scoped', receiverID);

		castAdapter.on('connected', function() {
			notifyObservers('connect');
		});

		castAdapter.on('disconnect', function() {
			notifyObservers('disconnect');
		});

		castAdapter.on('available', function() {
			castAvailable = true;
			notifyObservers('available');
		});

		try {
			var ssc = cast.framework.CastContextEventType.SESSION_STATE_CHANGED;
			cast.framework.CastContext.getInstance().addEventListener(ssc, function(event) {
				if(event.sessionState === cast.framework.SessionState.SESSION_RESUMED) {
					notifyObservers('sessionResume', {
						title: event.session.getApplicationStatus(),
					});
				}
			});
		} catch(e) {
			notifyObservers('error', e);
		}

		castAdapter.on('state', function(state) {
			switch(state) {
				case 'IDLE':
					stopBorrowInterval();
				break;
				case 'PLAYING':
					try {
						castAdapter.Session.addMessageListener('urn:x-cast:pl.jambox.go.cast.feedback', receiverEventHandler);
						castAdapter.Session.addMessageListener('urn:x-cast:pl.jambox.go.cast.metadata', receiverEventHandler);
					} catch(e) {
						// rzuca jesli wykryte zostanie inne urzadzenie z chrmoecastem, które
						// obecnie coś odtwarza. Można zignorować?
					}
					startBorrowInterval();
				break;
			}

			notifyObservers('state', state);
		});
	}

	var available = function() {
		return castAvailable;
	}

	var connected = function() {
		try {
			if(chromecast.is(castAdapter)) {
				return castAdapter.Connected;
			} else {
				return false;
			}
		} catch(e) {
			return false;
		}
	}

	var getMedia = function() {
		if(chromecast.is(castAdapter)) {
			return castAdapter.Media;
		} else {
			return false;
		}
	}

	var hasContent = function() {
		if(chromecast.is(castAdapter)) {
			return !!castAdapter.Media.content;
		} else {
			return false;
		}
	}

	var sendMessage = function(message) {
		if(!chromecast.is(castAdapter)) throw new Error('not instantiated');
		if(!castAdapter.Session) throw new Error('no session');

		castAdapter.Session.sendMessage('urn:x-cast:pl.jambox.go.cast.authdata', message).catch(function(e) {
			notifyObservers('error', e)
		});
	}

	var zap = function(url, title, description, channel) {
		if(typeof url === 'undefined' || !url) throw new Error('no url specified');

		// trzeba zmienić żeby było odzwierciedlone w authdata
		channelId = channel;

		getSessionData().then(function(session) {
			url = url.replace(/hash=[^&]*/i, 'hash='+encodeURIComponent(session.id));
			return getOttToken(session.id);
		}).then(function(token) {
			url = url.replace(/token=[^&]*/i, 'token='+encodeURIComponent(token));
			return url;
		}).then(function(url) {
			var session = cast.framework.CastContext.getInstance().getCurrentSession();
			if(!session) throw new Error('could not get session');

			var mediaInfo = new chrome.cast.media.MediaInfo(url);
			mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();

			if(title) mediaInfo.metadata.title = title;
			if (description) mediaInfo.metadata.subtitle = description

			var request = new chrome.cast.media.LoadRequest(mediaInfo);
			request.currentTime = 0;
			request.autoplay = true;

			session.loadMedia(request).then(function() {
				notifyObservers('start');
				notifyObservers('zap', { title: title });
			}).catch(function() {
				notifyObservers('error', 'failed to zap');
			});
		}).catch(function(e) {
			notifyObservers('error', e);
		});
	}

	var zapDash = function(uuid, title, description, channel) {
		channelId = channel;

		var castId = null;
		var castSeed = null;

		api.castGetSession().then(function(session) {
			castId = session.data.id;
			castSeed = session.data.seed;
			
			return Promise.all([
				api.castGetToken(castId),
				api.ottDash(uuid),
			]);
		}).then(function(response) {
			var playlistData = response[1].data;

			if(!playlistData.url.match(/^https?/)) {
				playlistData.url = 'https:'+playlistData.url;
			}

			var session = cast.framework.CastContext.getInstance().getCurrentSession();
			if(!session) throw new Error('could not get session');

			var mediaInfo = new chrome.cast.media.MediaInfo(playlistData.url);
			mediaInfo.contentType = 'application/dash+xml';
				
			mediaInfo.customData = {
				id: castId,
				seed: castSeed,
				license: playlistData.license,
			};

			mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
			if(title) mediaInfo.metadata.title = title;
			if (description) mediaInfo.metadata.subtitle = description

			var request = new chrome.cast.media.LoadRequest(mediaInfo);
			request.currentTime = 0;
			request.autoplay = true;

			session.loadMedia(request).then(function() {
				notifyObservers('start');
				notifyObservers('zap', { title: title });
			}).catch(function() {
				notifyObservers('error', 'failed to zap');
			});
		});
	}

	var setVolume = function(newVolume) {
		var player = castAdapter.Player;
		var ctrlr = castAdapter.Controller;

		player.volumeLevel = newVolume/100;
		ctrlr.setVolumeLevel();

		if(player.isMuted) ctrlr.muteOrUnmute();
	}

	return {
		prepare: prepare,
		destroy: destroy,
		start: start,
		startDash: startDash,
		addObserver: addObserver,
		removeObserver: removeObserver,
		available: available,
		connected: connected,
		getMedia: getMedia,
		hasContent: hasContent,
		sendMessage: sendMessage,
		zap: zap,
		zapDash: zapDash,
		setVolume: setVolume,
		getAdapter: function() { return castAdapter; },
	}

}]);