angular.module('webpanel').service('socket', ['$interval', '$timeout', 'auth', 'toast', 'api', '$window', function($interval, $timeout, auth, toast, api, $window) {
	var observers = {};

	var socket;
	var socketUri = "wss://api-dev.sgtsa.pl:56700" || 'wss://api.sgtsa.pl:56700';
	var pingInterval;

	var reconnectTimeout;
	var reconnectConfirmTimeout;
	var dcToastShown = false;

	var addObserver = function(name, event, observer) {
		if(typeof event !== 'string') return;
		if(typeof observers[name] !== 'undefined') return;

		if(typeof observers[event] === 'undefined') observers[event] = {};
		observers[event][name] = observer;
	}

	var removeObserver = function(event, name) {
		delete observers[event][name];
	}

	var notifyObservers = function(eventData) {
		var eventFragments = eventData.split(' ');
		var eventType = eventFragments[0];
		var eventDetails = eventFragments[1];

		for(var i in observers[eventType]) observers[eventType][i](eventDetails);
	}

	var start = function() {
		// zabezpieczenie przed ponowieniem po wylogowaniu
		if(auth.isDemo() || !auth.getId() || !auth.hasSeed() || !auth.getImpersonating()) return;

		var authParams = auth.getHeaders(auth.getImpersonating()).headers;

		var socketUriWithParams = socketUri+
			'?client='+encodeURIComponent(auth.getId())+
			'&impersonate='+encodeURIComponent(auth.getImpersonating())+
			'&hash='+encodeURIComponent(authParams['X-Auth'])+
			'&nonce='+encodeURIComponent(authParams['X-Nonce'])
		;

		socket = new WebSocket(socketUriWithParams);

		socket.onmessage = function(message) {
			notifyObservers(message.data);
		}

		socket.onclose = function(event) {
			if(event.code === 1006) {
				$timeout.cancel(reconnectTimeout);
				$timeout.cancel(reconnectConfirmTimeout);

				stop();

				api.ping().then(function() { // mamy sesję, czyli tylko komunikacja z envoy nie działa
					reconnectTimeout = $timeout(retry, 60*1000);

					if(!dcToastShown) toast.push('Błąd połączenia z usługą powiadomień. Niektóre funkcje interaktywne mogą nie być dostępne.');
					dcToastShown = true;
				}).catch(function() { // nie mamy sesji, więc wyloguj
					if(auth.isDemo()) return; // ale tylko jeśli to nie konto demo

					// auth.destroy();
					$window.location.reload();
				});
			}
		}

		pingInterval = $interval(function() {
			if(socket.readyState == 3) socket = new WebSocket(socketUriWithParams);
			socket.send(null);
		}, 30*1000);
	}

	var retry = function() {
		$timeout.cancel(reconnectTimeout);
		start();

		reconnectConfirmTimeout = $timeout(function() {
			if(dcToastShown) {
				toast.push('Odzyskano połączenie z usługą powiadomień.');
				dcToastShown = false;
				$timeout.cancel(reconnectConfirmTimeout);
			}
		}, 90*1000);
	}

	var stop = function() {
		try {
			socket.close();
		} catch(e) {}

		$interval.cancel(pingInterval);
		socket = undefined;
		pingInterval = undefined;
	}

	return {
		start: start,
		stop: stop,
		addObserver: addObserver,
		removeObserver: removeObserver,
	}

}]);