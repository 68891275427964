angular.module('webpanel').controller('LoginController', ['$scope', '$rootScope', '$routeParams', '$window', '$timeout', 'api', 'auth', 'device', 'localStorageService', 'socket',
	function($scope, $rootScope, $routeParams, $window, $timeout, api, auth, device, storage, socket) {

	$scope.userData = {
		username: '',
		password: '',
	}

	$scope.remindData = {
		email: '',
		password: '',
		passwordRepeat: '',
		customerId: '',
		token: '',
	}

	$scope.devices = [];
	$scope.selectedDevice = null;

	$scope.loggedIn = false;
	$scope.panelVisible = false;
	$scope.mode = 'login';

	$scope.hasInputFocus = false;

	$scope.isHome = true;
	$scope.homeTooltipVisible = false;

	$scope.listVisible = false;

	$scope.linked = false;

	var blurDebounce;
	var hasInitialized = false;

	var hasSelectedFirstDevice = false;
	var socketTimeout;

	var storeUserAction = false;
	var previousRoute = '/';

	var tryAutoLogin = function() {
		if(!auth.getId() || !auth.hasSeed()) {
			auth.destroy(true);
			auth.initDemo();
		}

		api.devicesGet().then(function(response) {
			if(!response.data.length) {
				$scope.analytics.trackEvent('user', 'login_auto_fail');
				auth.destroy();
				return;
			}

			$timeout(function() {
				device.set(response.data);
				$scope.loggedIn = true;

				if(!storeUserAction) {
					$scope.panelVisible = false;
				}

				routeChangeCallback($scope.location.path());

				$timeout.cancel(socketTimeout);
				socketTimeout = $timeout(socket.start, 3000);

				if(!auth.isDemo()) {
					$rootScope.$emit('appConfigChange', { what: 'auth', state: true });
					$scope.analytics.trackEvent('user', 'login_auto_success');
				} else {
					$scope.analytics.trackEvent('user', 'login_demo_auto_success');
				}

			}, 0);

			clearLoginData();
		}).catch(function() {
			auth.destroy(true);
			var pop = $scope.popup.create();
			pop.setText('Nie udało się załadować strony. Czy chcesz spróbować ponownie?');
			pop.addButton('OK', function(){
				$window.location.reload();
			}, 'yes', true);
			$scope.popup.push(pop);
		});
	}

	var clearLoginData = function() {
		$scope.remindData = {
			email: '',
			password: '',
			passwordRepeat: '',
			customerId: '',
			token: '',
		}

		$scope.userData.password = '';
	}

	var setPasswordToken = function(token) {
		$scope.mode = 'set';
		$scope.remindData.token = token;
		$scope.panelVisible = true;
	}

	var routeChangeCallback = function(nextPath) {
		try {
			if(nextPath.match(/^\/telewizja\/(nagrania|moja\-lista\-100|ochrona\-rodzicielska|wyszukaj|wideo\-na\-zyczenie\-vod)/i)) {
				if($rootScope.access.card) {
					$scope.location.path('/');
					return;
				}
			}
			if(nextPath.match(/^\/uslugi\-mobilne/i)) {
				if(!$rootScope.access.mobile) {
					$scope.location.path('/');
					return;
				}
				device.setMode('simcard', storage.get('lastDevice'));
			} else if(nextPath.match(/^\/(uslugi\-tv|telewizja|urzadzenia)/i)){
				if(!$rootScope.access.tv) {
					$scope.location.path('/');
					return;
				}
				device.setMode('stb', storage.get('lastDevice'));
			} else if(nextPath.match(/^\/(login|remind)/i)){
				$scope.location.path('/');
				var baseHref = document.getElementsByTagName('base')[0].getAttribute('href');
				if(!baseHref) baseHref = '/';

				$window.location.href = baseHref;
			}
		} catch(e) {
			// jeśli nie ma originalPath, to zostaw jak było (fallback).
		}
	}

	var init = function() {
		clearLoginData();
		$scope.mode = 'login';

		if($routeParams.mode) {
			if (($routeParams.mode === 'login' || $routeParams.mode === 'remind') && $scope.user.isDemo()) {
				$scope.linked = true;
				$scope.mode = $routeParams.mode;
				clearLoginData();
				$scope.panelVisible = true;
			} else {
				$scope.location.path('/');
				var baseHref = document.getElementsByTagName('base')[0].getAttribute('href');
				if(!baseHref) baseHref = '/';
				$window.location.href = baseHref;
			}
		} else if(typeof $routeParams.passwordToken !== 'undefined') {
			if(!auth.getId() || auth.isDemo()) {
				setPasswordToken($routeParams.passwordToken);
			} else {
				$scope.location.path('/');
				$scope.location.replace();
			}
		} else {
			tryAutoLogin();
		}
	}

	$scope.proceedWithLogin = function(shouldRemember) {
		$scope.analytics.trackEvent('user', 'login_remember', !!shouldRemember);

		if(shouldRemember) {
			api.userPairProlong().then(function() {
				console.log('pair prolonged');

			}).catch(function() {
				console.log('pair prolong fail');
				// ???
			})
		}

		$rootScope.$emit('appConfigChange', { what: 'auth', state: true });
		$scope.loggedIn = true;
		// to jest po kliknięciu buttona z palca, więc tutaj możemy wyczyścić flagę
		if (!$scope.linked) $scope.panelVisible = false;

		routeChangeCallback($scope.location.path());
	}

	$scope.login = function(waitChallengeResponse) {
		if(!$scope.userData.username) return;

		api.userLogin($scope.userData.username, $scope.userData.password, waitChallengeResponse).then(function(response) {
			auth.destroy(true);

			auth.setId(response.data.id);
			auth.setSeed(response.data.seed);

			hasSelectedFirstDevice = false;
			storeUserAction = false;

			clearLoginData();

			$timeout(function() {
				if(!response.data.devices.length) {
					auth.destroy();

					var pop = $scope.popup.create();
					pop.setText('Nie posiadasz żadnych urządzeń. Korzystanie z JAMBOX go! możliwe '+
						'jest tylko w przypadku posiadania choć jednego urządzenia.');
					pop.addButton('OK', angular.noop, 'yes', true);
					$scope.popup.push(pop);

					$scope.analytics.trackEvent('user', 'login_no_devices');
					return;
				}

				device.set(response.data.devices);
				$scope.analytics.trackEvent('user', 'login_success');

				$scope.mode = 'ask-remember';
				$scope.hasInputFocus = false;

				$timeout.cancel(socketTimeout);
				socketTimeout = $timeout(socket.start, 3000);
			}, 0);
		}).catch(function(response) {
			if(response.status === 429) {
				$scope.mode = 'wait';
				$scope.analytics.trackEvent('user', 'login_challenge');
			} else {
				// jeśli wracamy z kapczy
				if($scope.mode === 'wait') {
					clearLoginData();
					$scope.mode = 'login';
				}

				var pop = $scope.popup.create();
				pop.setText('Nie udało się zalogować. Upewnij się, że podane dane są poprawne.');
				pop.addButton('OK', angular.noop, 'yes', true);
				$scope.popup.push(pop);

				$scope.analytics.trackEvent('user', 'login_fail');
			}
		});
	}

	$scope.waitChallengeCompleted = function(response) {
		console.log(response);
		$scope.analytics.trackEvent('user', 'login_challenge_response');
		$scope.login(response);
	}

	$scope.sendRemindMail = function() {
		var pop = $scope.popup.create();

		api.passwordReset($scope.remindData.email).then(function() {
			pop.setText('E-mail z instrukcją resetowania hasła został wysłany. Zawarty w wiadomości link resetujący hasło będzie ważny przez godzinę. Sprawdź swoją skrzynkę odbiorczą.');
			pop.addButton('OK', angular.noop, 'yes', true);
			$scope.popup.push(pop);

			clearLoginData();
			$scope.analytics.trackEvent('user', 'login_remind_success');
		}).catch(function() {
			pop.setText('Próba wysłania e-maila nie powiodła się.');
			pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
			$scope.popup.push(pop);

			clearLoginData();
			$scope.analytics.trackEvent('user', 'login_remind_fail');
		});
	}

	$scope.confirmChangePassword = function() {
		var pop = $scope.popup.create();

		if($scope.remindData.password.length < 8) {
			pop.setText('Nowe hasło musi mieć długość co najmniej 8 znaków i zawierać dużą literę oraz cyfrę.');
			pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
			$scope.popup.push(pop);

			$scope.remindData.password = '';
			$scope.remindData.passwordRepeat = '';

			return;
		}

		if($scope.remindData.password !== $scope.remindData.passwordRepeat) {
			pop.setText('Nowe hasło musi mieć długość co najmniej 8 znaków i zawierać dużą literę oraz cyfrę. Wpisane hasła muszą się zgadzać.');
			pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
			$scope.popup.push(pop);

			$scope.remindData.password = '';
			$scope.remindData.passwordRepeat = '';

			return;
		}

		api.passwordResetConfirm($scope.remindData.customerId, $scope.remindData.token, $scope.remindData.password).then(function() {
			pop.setText('Hasło zostało zmienione.');
			pop.addButton('OK', $scope.cancelRemindSecondStep, 'yes', true);
			$scope.popup.push(pop);

			clearLoginData();
			$scope.analytics.trackEvent('user', 'login_reset_success');
		}).catch(function() {
			pop.setText('Podczas próby ustawienia nowego hasła wystąpił błąd.');
			pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
			$scope.popup.push(pop);

			$scope.analytics.trackEvent('user', 'login_reset_fail');

			$scope.remindData.password = '';
			$scope.remindData.passwordRepeat = '';
		});
	}

	$scope.remind = function() {
		clearLoginData();
		$scope.mode = 'remind';
		$scope.analytics.trackEvent('user', 'login_remind');
	}

	$scope.cancelChallenge = function() {
		clearLoginData();
		$scope.mode = 'login';
		$scope.analytics.trackEvent('user', 'login_challenge_cancel');
	}

	$scope.cancelRemind = function() {
		clearLoginData();
		$scope.mode = 'login';
	}

	$scope.cancelRemindSecondStep = function() {
		clearLoginData();
		$scope.location.path('/');
		tryAutoLogin();

		$scope.panelVisible = false;
		$scope.mode = 'login';

		$scope.analytics.trackEvent('user', 'login_remind_cancel');
	}

	$scope.select = function(id, mode) {
		var lastDevice = storage.get('lastDevice');

		if(
			!lastDevice ||
			typeof lastDevice !== 'object' ||
			typeof lastDevice.stb === 'undefined' ||
			typeof lastDevice.simcard ==='undefined'
		) {
			lastDevice = { stb: null, simcard: null }
		}

		lastDevice[mode] = id;
		storage.set('lastDevice', lastDevice);

		$scope.analytics.trackEvent('device', 'select');

		if(device.select(id, mode)) {
			socket.stop();
			$timeout.cancel(socketTimeout);
			socketTimeout = $timeout(socket.start, 3000);

			$rootScope.$broadcast('deviceMenuHide');
		}
	}

	$scope.toggleList = function() {
		$rootScope.$broadcast('deviceMenuToggle');
		$scope.hideHomeTooltip();
		$scope.listVisible = !$scope.listVisible;
	}

	$scope.closeDeviceList = function() {
		$rootScope.$broadcast('deviceMenuHide');
		$scope.listVisible = false;
	}

	$scope.toggleHomeTooltip = function($event) {
		$scope.homeTooltipVisible = !$scope.homeTooltipVisible;
		$event.stopPropagation();
		$scope.closeDeviceList();
	}

	$scope.hideHomeTooltip = function() {
		$scope.homeTooltipVisible = false;
	}

	$scope.seeAllDevices = function() {
		$scope.location.path('/urzadzenia/obecne');
	}

	$scope.seeAllCards = function() {
		$scope.location.path('/uslugi-mobilne/twoje-numery');
	}

	$scope.focusInput = function() {
		$timeout.cancel(blurDebounce);
		$scope.hasInputFocus = true;
	}

	$scope.blurInput = function() {
		blurDebounce = $timeout(function() {
			$scope.hasInputFocus = false;
		}, 300);
	}

	$scope.openLoginPanel = function() {
		// muszę tu użyć natywnego API bo $locationChangeStart nie jest wyzwalany
		// przy przechodzeniu przyciskiem wstecz do innej domeny
		if(!storeUserAction) history.pushState(null, null, document.URL);

		clearLoginData();
		$scope.panelVisible = true;
	}

	var doMagicRedirectFuckery = function(target) {
		$timeout(function() {
			$scope.location.path(target);
		}, 0);

		$timeout(function() {
			$scope.$evalAsync(function() {
				// what the fuck?
				$scope.location.path(target);
			});
		}, 1);
	}

	$scope.closeLoginPanelWithButton = function() {
		if(storeUserAction) {
			storeUserAction = false;
			doMagicRedirectFuckery(previousRoute);
			return;
		}

		// tutaj musimy z palca wyzwolic history.back()
		// żeby pozbyć się wpisu dodanego przez hack
		history.back();
		$scope.closeLoginPanel();
	}

	$scope.closeLoginPanel = function() {
		history.replaceState(null, null, document.URL);

		if (!$scope.linked) $scope.panelVisible = false;
		clearLoginData();
	}

	// brzydki hack na przycisk wstecz nie powodujący $locationChangeStart
	// przy przechodzeniu do innej domeny pod przyciskiem (np .jesli user wszedł z
	// google, to zamiast zmaknąć panel logowania, przekieruje na google)
	window.addEventListener('popstate', function() {
		if($scope.panelVisible && !storeUserAction) {
			$scope.closeLoginPanel();
		}
	});

	// ------------------------------------------------------

	device.addObserver('loginDeviceObserver', function(type, data) {
		switch(type) {
			case 'change':
			case 'nameChange':
				$scope.devices = device.get();
				$rootScope.$broadcast('deviceMenuFill', $scope.devices);

				if(!hasSelectedFirstDevice) {
					var lastDevice = storage.get('lastDevice');
					var deviceMode = device.getMode();

					if(lastDevice) {
						$scope.select(lastDevice[deviceMode], deviceMode);
					} else {
						var devicesForMode = device.get();
						$scope.select(devicesForMode[Object.keys(devicesForMode)[0]].id, deviceMode);
					}

					hasSelectedFirstDevice = true;
				} else {
					$scope.selectedDevice = device.getSelected();
				}
			break;
			case 'selectSuccess':
				$scope.selectedDevice = device.getSelected();
			break;
			case 'roamChange':
				$scope.isHome = !!data;
			break;
		}
	});

	$timeout(function() {
		auth.addObserver('loginAuthObserver', function(type) {
			// asynchroniczna zmiana danych usera, np. wylogowanie na innej karcie
			if(type === 'credChangeOol' && !document.hasFocus()) $window.location.reload();
		});
	}, 1000);

	// ------------------------------------------------------

	$rootScope.$on('deviceSelect', function(_, device) {
		$scope.select(device.id, device.type);
	});

	$rootScope.$on('deviceMenuSeeAll', function() {
		switch($scope.selectedDevice.type) {
			case 'stb':
				$scope.seeAllDevices();
			break;
			case 'simcard':
				$scope.seeAllCards();
			break;
		}
	});

	$rootScope.$on('loginRequest', $scope.openLoginPanel);

	$rootScope.$on('LoginControllerStoreAction', function() {
		storeUserAction = true;
	});

	$scope.$on('$destroy', function() {
		device.removeObserver('loginDeviceObserver');
		auth.removeObserver('loginAuthObserver');
	});

	// jeśli user wciśnie wstecz w przeglądarce i tego nie obsłużymy,
	// aplikacja obsłuży routeChange event ale nie ukryje panelu logowania

	$scope.$on('$locationChangeStart', function(event, next) {
		if(next && $scope.panelVisible && ['set', 'remind'].indexOf($scope.mode) < 0) {
			$scope.closeLoginPanel();
			if(storeUserAction) {
				// BACK po zmianie lokacji. Nic nie robimy z eventem, ale trzeba
				// wyczyścić flagę jak przy wyjściu buttonem.
				storeUserAction = false;
			} else {
				// BACK na ekranie logowania z buttona "zaloguj się" albo innego buttona
				// w UI odsyłającego do logowania (nie nastąpiła zmiana lokacji).
				// nie chcemy cofać się do poprzedniej lokacji, tylko zamknąć popup.
				// jak ni ezamkniemy popupa, to aplikacja zrobi BACK ale popup dalej będzie
				// nad nowym kontrolerem z poprzedniego routa. Trzeba obsłużyć "idź do poprzedniego
				// kontrolera" w inny sposob...
				event.preventDefault();
			}
		}
	});

	$scope.$on('$routeChangeSuccess', function(_, current, previous) {
		if(hasInitialized) {
			if(current) {
				routeChangeCallback(current.$$route.originalPath);
			} else {
				// czy to w ogóle wystąpi?
				routeChangeCallback(previous.$$route.originalPath);
			}
			return;
		}

		hasInitialized = true;

		if(typeof previous !== 'undefined' && typeof previous.$$route !== 'undefined' && typeof previous.$$route.originalPath !== 'undefined') {
			previousRoute = previous.$$route.originalPath;
		} else {
			previousRoute = '/';
		}

		if(current.$$route.originalPath === '/') {
			init();
		} else {
			// reset hasła itp.
			$timeout(init, 0);
		}
	});

	$timeout(init, 0);

}]);