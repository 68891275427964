angular.module('webpanel').controller('VodController', ['$scope', '$rootScope', '$routeParams', 'api', 'device', 'toast', function($scope, $rootScope, $routeParams, api, device, toast) {
	$scope.data = [];
	$scope.content = [];

	$scope.selectedKiosk = null;
	$scope.selectedCategory = null;
	$scope.selectedAsset = null;

	$scope.isSingleCategory = false;
	$scope.mobileCategorySelectorVisible = false;
	$scope.noop = angular.noop;

	$scope.tvodPricing = {};
	$scope.chosenBrick = null;

	var resultsPerPage = 10;

	var selectedLeaseAsset = null;

	var isInfiniteLoading = false;
	var stopInfiniteScroll = false;
	var page = 0;

	var playQueue = [];
	var leasedAssets = []

	// nie chcemy żeby user wypożyczał zanim się załaduje lista tego co ma wypożyczone
	$scope.canLease = false;

	var ageRatingDict = {
		'od12': 12,
		'od16': 16,
		'bezo': 1,
		'od18': 18,
		'brak': 1,
		'od7': 7,
		'od07': 7,
	};

	var errorReasons = {
		generic: 'Nie udało się załadować listy materiałów.',
		add: 'Nie udało się dodać materiału do kolejki.',
		remove: 'Nie udało się usunąć materiału z kolejki.',
		genericTvod: 'Nie udało się pobrać listy zamówionych filmów.',
	};

	var fetch = function() {
		var promiseList = [];
		if($scope.user.isDemo()) {
			// kod się spodziewa 5 promisów z poprawną zwracaną wartością
			promiseList = [
				api.vodGet(),
				Promise.resolve({ data: [] }),
				Promise.resolve([]),
				Promise.resolve([]),
				Promise.resolve({ data: [] }),
			];
		} else {
			promiseList = [
				api.vodGet(),
				api.tVodGet(),
				listCustom(),
				listLeases(),
				api.tVodGetTicketPrices(),
			];
		}

		Promise.all(promiseList).then(function(response) {
			var tVodKiosks = response[1].data;

			playQueue = response[2];
			leasedAssets = response[3];

			$scope.data = response[0].data;
			$scope.tvodPricing = response[4].data;

			$scope.data.kioskList = [];

			for(var i in tVodKiosks.kiosk) {
				$scope.data.kiosk[i] = tVodKiosks.kiosk[i];
				$scope.data.category[i] = tVodKiosks.category[i];
				$scope.data.kiosk[i].tvod = true;
				$scope.data.kioskList.push($scope.data.kiosk[i]);
			}

			for(var i in $scope.data.kiosk) {
				$scope.data.kiosk[i].kioskId = i;
				$scope.data.kiosk[i].image = $scope.data.kiosk[i].image.replace('/vod_logos/', '');
				if(typeof $scope.data.kiosk[i].tvod === 'undefined') {
					$scope.data.kiosk[i].tvod = false;
					$scope.data.kioskList.push($scope.data.kiosk[i]);
				}
			}

			if(!$scope.user.isDemo()) {
				$scope.data.kiosk[0] = { name: 'Do obejrzenia', kioskId: '0' }
				$scope.data.category[0] = [{id: 0, name: 'Do obejrzenia'}];
				$scope.data.kioskList.unshift($scope.data.kiosk[0]);
			}
			
			var firstKiosk = $scope.user.isDemo() ? 0 : 1;

			if($routeParams.kioskId && $scope.data.kiosk[$routeParams.kioskId]) {
				var categoryIndex = 0;
				var paramCategory = $scope.data.category[$routeParams.kioskId];

				for(var i in paramCategory) {
					if(parseInt(paramCategory[i].id) === parseInt($routeParams.categoryId)){
						categoryIndex = i;
						break;
					}
				}
				
				$scope.selectKiosk($routeParams.kioskId, false);
				$scope.selectCategory(categoryIndex, false);

				if($routeParams.filmId) {
					api.vodGetOne($routeParams.filmId).then(function(response) {
						if(response.data.length) {
							console.log(response.data[0]);
							$scope.selectAsset(response.data[0]);
						}
					});
				}
			} else {
				$scope.selectKiosk($scope.data.kioskList[firstKiosk].kioskId);
			}

		}).catch(function() {
			handleLoadError(errorReasons.generic);
		});
	}


	var listCustom = function() {
		return new Promise(function(resolve, reject) {
			if($scope.user.isDemo()) resolve([]);

			api.vodCustomQueueList().then(function(response) {
				resolve(response.data)
			}).catch(function() {
				reject(errorReasons.generic);
			});
		});
	}

	var listLeases = function() {
		return new Promise(function(resolve, reject) {
			if($scope.user.isDemo()) resolve([]);

			api.tVodGetLeases().then(function(response) {
				$scope.canLease = true;
				resolve(response.data.films);
			}).catch(function() {
				reject(errorReasons.genericTvod);
			});
		});
	}

	device.addObserver('vodDeviceObserver', function(type) {
		if(device.getMode() != 'stb') return;

		switch(type) {
			case 'selectSuccess':
				fetch();
			break;
		}
	});

	device.addObserver('watchDeviceObserver', function(type) {
		if($scope.user.isDemo()) return;
		if (type === 'selectSuccess' && !$rootScope.access.vod) return $scope.location.path('/');

	});

	$scope.selectKiosk = function(id, selectFirstCategory) {
		if(id == $scope.selectedKiosk) return;
		if(typeof selectFirstCategory === 'undefined') selectFirstCategory = true;
		
		$scope.selectedKiosk = id;
		$scope.selectedCategory = -1;
		
		
		// jeśli jest jedna kategoria, nie pokazuj wybieraczki...
		$scope.isSingleCategory = ($scope.data.category[$scope.selectedKiosk].length < 2);
		
		if(selectFirstCategory) $scope.selectCategory(0);
		$scope.analytics.trackEvent('vod', 'kiosk_select', id);
	}
	
	$scope.selectCategory = function(id, clearLocation) {
		$scope.closePane();
		id = parseInt(id);
		if(typeof clearLocation === 'undefined') clearLocation = true;

		$scope.mobileCategorySelectorVisible = false;

		// kategoria jest resetowana w selectKiosk
		if(id === $scope.selectedCategory) return;

		$scope.contentOffset = 0;
		$scope.selectedCategory = id;
		$scope.selectedAsset = null;

		page = 0;
		$scope.content = [];

		stopInfiniteScroll = false;
		loadAsset(id);

		if(clearLocation) $scope.location.update_path('/telewizja/wideo-na-zyczenie-vod', true);
		$scope.analytics.trackEvent('vod', 'category_select', id);
	}

	$scope.orderCheck = function(assetId, priceGroup) {
		selectedLeaseAsset = assetId;

		api.tVodGetLeases().then(function(response) {
			if(typeof response.data.leases === 'undefined') throw new Error('bad response');

			if(typeof response.data.leases[priceGroup] !== 'undefined' && response.data.leases[priceGroup].count > 0) {
				var pop = $scope.popup.create();
				pop.setText('Wypożyczenie filmu zostało już rozliczone. Czy chcesz wypożyczyć ten film?');

				pop.addButton('Wypożycz', function() {
					lease(assetId).then(function() {
						toast.push('Film został dodany do kolejki "Do obejrzenia".');
						$scope.analytics.trackEvent('order', 'lease_success', assetId);
					});
					$scope.analytics.trackEvent('tvod', 'order_free_complete', assetId);
				}, 'yes');

				pop.addButton('Anuluj', function() {
					$scope.analytics.trackEvent('tvod', 'order_free_reject', assetId);
					angular.noop();
				}, 'no', true);

				$scope.popup.push(pop);
				$scope.analytics.trackEvent('tvod', 'order', assetId);
			} else {
				$scope.order(assetId);
			}
		}).catch(function() {
			var pop = $scope.popup.create();
			pop.setText(errorReasons.genericTvod);
			pop.addButton('OK', angular.noop, 'yes');
			$scope.popup.push(pop);
		});
		$scope.closePane();
	}

	var lease = function(assetId) {
		selectedLeaseAsset = assetId;

		return api.tVodLease(selectedLeaseAsset).then(function() {
			return api.vodCustomQueuePush(selectedLeaseAsset);
		}).then(function() {
			for(var i in $scope.content) {
				if($scope.content[i].id === selectedLeaseAsset) {
					$scope.content[i].leased = true;
					break;
				}
			}

			leasedAssets.push(selectedLeaseAsset);
			playQueue.push(selectedLeaseAsset);
		});
	}

	$scope.order = function(assetId) {
		if(!$scope.checkHasEmail()) return;

		selectedLeaseAsset = assetId;

		api.tVodGetTicketPackage(assetId).then(function(response) {
			$scope.selectedPackage = response.data;
			$scope.analytics.trackEvent('order', 'select', $scope.selectedPackage.name);
		});
	}

	$scope.orderOnCancel = function() {
		$scope.selectedPackage = null;
		selectedLeaseAsset = null;
	}

	$scope.orderOnSuccess = function() {
		lease(selectedLeaseAsset).then(function() {
			selectedLeaseAsset = null;
			$scope.selectedPackage = null;
		}).catch(function() {
			var pop = $scope.popup.create();
			pop.setText('Nie udało się wypożyczyć materiału. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes');
			$scope.popup.push(pop);

			$scope.analytics.trackEvent('order', 'lease_fail', assetId);
		})
	}

	var handleLoadError = function(message) {
		var pop = $scope.popup.create();
		pop.setText(message+ ' Spróbuj ponownie.');
		pop.addButton('OK', angular.noop, 'yes', true);
		$scope.popup.push(pop);
	}

	var loadAsset = function(categoryId, fromInfiniteScroll) {
		if(typeof fromInfiniteScroll === 'undefined') fromInfiniteScroll = false;
		if(!$scope.data || !$scope.selectedKiosk) return;

		var pageWhenRequesting = page;

		if(parseInt($scope.selectedKiosk) === 0) {
			api.vodCustomQueueGet(page*resultsPerPage, resultsPerPage).then(function(response) {
				loadAssetCallback(response.data, fromInfiniteScroll, pageWhenRequesting);
			}).catch(function() {
				handleLoadError(errorReasons.generic);
			});
		} else {
			api.vodCategoryGet($scope.data.category[$scope.selectedKiosk][categoryId].id, page*resultsPerPage).then(function(response) {
				loadAssetCallback(response.data, fromInfiniteScroll, pageWhenRequesting);
			}).catch(function() {
				handleLoadError(errorReasons.generic);
			});
		}
	}

	var loadAssetCallback = function(responseData, fromInfiniteScroll, page) {
		if(!fromInfiniteScroll) {
			try {
				document.querySelector('.vod__content__list').scrollTop = 0;
			} catch(e) {}
		}

		isInfiniteLoading = false;

		if(responseData.length) {
			for(var i=0; i<responseData.length; i++) {
				if(!responseData[i]) continue;
				// musimy wrzucic wyniki na poprawną "stronę" bo requesty idą asynchronicznie

				responseData[i].queued = (playQueue.indexOf(parseInt(responseData[i].id)) > -1);
				responseData[i].leased = (leasedAssets.indexOf(parseInt(responseData[i].id)) > -1);

				responseData[i].rating = ageRatingDict[responseData[i].rating];
				if(!responseData[i].rating) responseData[i].rating = 0;

				$scope.content[(page*resultsPerPage)+i] = responseData[i];
			}
		} else {
			if(page === 0) {
				$scope.content = [];
			} else {
				// nie ma więcej wyników więc nie chcemy kolejnych requestów
				stopInfiniteScroll = true;
			}
		}
	}

	$scope.scrollEnd = function(_, scrollTop, __, ___, element) {
		if(isInfiniteLoading || stopInfiniteScroll) return;

		var scrollThreshold = element.scrollHeight - element.clientHeight;

		if(scrollTop + 400 > scrollThreshold) {
			isInfiniteLoading = true;
			page += 1;
			loadAsset($scope.selectedCategory, true);
		}
	}

	$scope.contentChanged = function(element, _, parent) {
		// jeśli wysokość wyników jest mniejsza niż wysokość kontenera,
		// dociągaj kolejne dopóki kontener nie zostanie wypełniony

		if(element.scrollHeight < parent.scrollHeight) {
			$scope.scrollEnd(null, 0, null, null, element);
		}
	}

	$scope.selectAsset = function(asset) {
		console.log(asset);
		$scope.selectedAsset = asset;
		$scope.chosenBrick = asset;
	}

	$scope.clearSelectedAsset = function(event) {
		// WTF? jakiś bug w chrome? czasami dostaję mouseout po kliknięciu klocka
		// https://stackoverflow.com/questions/47649442/
		if(event.relatedTarget === null) return;

		// fuj fuj fuj
		event.target.closest('li').querySelector('.vod__content__list__extension__inner__info p').scrollTop = 0;

		$scope.selectedAsset = null;
	}

	$scope.toggleMobileCategorySelector = function() {
		$scope.mobileCategorySelectorVisible = true;
	}

	// ta metoda używana przez testy
	$scope.isInQueue = function(assetId) {
		return playQueue.indexOf(parseInt(assetId)) > -1;
	}

	$scope.enqueue = function(assetId) {
		if(!$scope.checkHasEmail()) return;

		assetId = parseInt(assetId);

		api.vodCustomQueuePush(assetId).then(function() {
			playQueue.push(assetId);

			for(var i in $scope.content) {
				if($scope.content[i].id === assetId) {
					$scope.content[i].queued = true;
					break;
				}
			}

			$scope.analytics.trackEvent('vod', 'customlist_add', assetId);
		}).catch(function(response) {
			switch(response.status) {
				case 402:
				case '402':
					var pop = $scope.popup.create();

					if(response.data.package) {
						pop.setText('Aby uzyskać dostęp do wybranego materiału, zamów pakiet z usługą '+response.data.package+'.');
						pop.addButton('Sprawdź ofertę', function() {
							$scope.location.path('/uslugi-tv/zamow-dodatki');
							$scope.analytics.trackEvent('order', 'from_vodkiosk');
						}, 'yes');
					} else {
						pop.setText('Ten materiał nie jest dostępny w twoim abonamencie. '+
						'Skontaktuj się ze swoim Operatorem i zapytaj o dostępność wybranego materiału.');
						pop.addButton('Przejdź do danych Operatora', function() {
							$scope.location.path('/twoje-konto/twoje-dane');
						}, 'yes', true);
					}

					pop.addButton('Nie teraz', angular.noop, 'no', true);
					$scope.popup.push(pop);

					$scope.analytics.trackEvent('vod', 'customlist_not_entitled', assetId);
				break;
				default:
					handleLoadError(errorReasons.add);
					$scope.analytics.trackEvent('vod', 'customlist_add_error', assetId);
			}
		});
	}

	$scope.dequeue = function(assetId) {
		if(!$scope.checkHasEmail()) return;

		assetId = parseInt(assetId);

		// żeby nie było jakichś dzikich race conditions
		var playQueueCopy = angular.copy(playQueue);
		var removedAssetPosition = null;

		for(var i in playQueueCopy) {
			if(playQueueCopy[i] === assetId) {
				playQueueCopy.splice(i, 1);
				removedAssetPosition = i;
				break;
			}
		}

		// jeśli jesteśmy na liście "do obejrzenia", a nie w prawdziwym kiosku
		if(parseInt($scope.selectedKiosk) === 0) {

			api.vodCustomQueueDelete(assetId).then(function() {
				$scope.analytics.trackEvent('vod', 'customlist_remove', assetId);
				return api.vodCustomQueueGet($scope.content.length, 1);
			}).then(function(resultOfGet) {
				// po tej zmianie trzeba będzie dociągnąć wyniki, bo mamy paginację
				// i powstaje rozjazd między początkami stron
				playQueue = playQueueCopy;
				var closingAsset = resultOfGet.data[0];

				$scope.content.splice(removedAssetPosition, 1);
				if(closingAsset) $scope.content.push(closingAsset);
			}).catch(function() {
				handleLoadError(errorReasons.remove);
				$scope.analytics.trackEvent('vod', 'customlist_remove_error', assetId);
			});
		} else {
			api.vodCustomQueueDelete(assetId).then(function() {
				playQueue = playQueueCopy;

				for(var i in $scope.content) {
					if($scope.content[i].id === assetId) {
						$scope.content[i].queued = false;
						break;
					}
				}

				$scope.analytics.trackEvent('vod', 'customlist_remove_kiosk', assetId);
			}).catch(function() {
				handleLoadError(errorReasons.remove);
				$scope.analytics.trackEvent('vod', 'customlist_remove_error', assetId);
			});
		}
	}

	$scope.closePane = function() {
		$scope.chosenBrick = null;
	}

	$scope.$on('$destroy', function() {
		device.removeObserver('vodDeviceObserver');
		device.removeObserver('watchDeviceObserver');
	});

	if(device.has() && device.getMode() === 'stb') {
		fetch();
	}
}]);